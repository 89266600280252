import { sendGTMEvent } from '@next/third-parties/google';

import { Location } from '../types';

import { GTMOrderData, GTMSurveyData, GTMTransactionData } from './types';
import { ONLINE_SELL_CONFIRMED_EVENT } from './consts';

export const pushGTMEventForOrder = async (
  { atmId, locationName }: GTMOrderData,
  eventName: string,
): Promise<void> => {
  sendGTMEvent({
    event: eventName,
    atm_id: atmId,
    store_name: locationName,
  });
};

export const pushGTMEventForATM = async (location: Location, eventName: string): Promise<void> => {
  try {
    const { name, prices, id: atmId } = location;

    if (!prices) {
      return;
    }

    sendGTMEvent({
      event: eventName,
      BATM: atmId,
      store_name: name,
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const pushGTMEvent = (data: object, eventCallback?: VoidFunction) => {
  if (!window.dataLayer) {
    eventCallback?.();

    return;
  }

  try {
    sendGTMEvent({
      ...data,
      eventCallback,
      eventTimeout: 30000,
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const pushSurveyReviewGTMEvent = async (data: GTMSurveyData): Promise<void> =>
  pushGTMEvent(data);

export const pushTransactionGTMEvent = async (data: GTMTransactionData): Promise<void> =>
  pushGTMEvent({
    ...data,
    event: ONLINE_SELL_CONFIRMED_EVENT,
  });
