import { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';

export const getInitialZoom = (lang: Lang) => {
  switch (lang) {
    case LOCALES.EN_HK:
    case LOCALES.ZH_HK:
      return 15;
    default:
      return 12;
  }
};
