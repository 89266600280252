import { TFunction } from 'i18next';

import { getCurrencyFromLang } from '@/app/[lang]/pre-sell/partials/PreSellForm/partials/Steps/ScanQrCodeStep/hooks';
import { Lang } from '@/types/locales';
import { formatNumber } from '@/utils';

export const makeFinalAmountValidator =
  (validAmounts: number[], locale: Lang, t: TFunction) => (value: unknown) => {
    if (typeof value !== 'number') {
      return t('calculator.invalidAmount');
    }

    if (validAmounts.includes(value)) {
      return true;
    }

    const nearestLowerAmountIndex = Math.max(
      0,
      validAmounts.findLastIndex((validAmount) => validAmount <= value),
    );
    const nearestHigherAmountIndex = Math.max(
      0,
      Math.min(nearestLowerAmountIndex + 1, validAmounts.length - 1),
    );

    const nearestLowerAmount = validAmounts[nearestLowerAmountIndex] || 0;
    const nearestHigherAmount = validAmounts[nearestHigherAmountIndex] || 0;

    const currency = getCurrencyFromLang(locale);

    const formattedNearestLowerAmount = formatNumber(nearestLowerAmount, {
      locale,
      currency,
    });
    const formattedNearestHigherAmount = formatNumber(nearestHigherAmount, {
      locale,
      currency,
    });

    const hint =
      nearestLowerAmountIndex === nearestHigherAmountIndex
        ? t('calculator.maximumWithdraw', {
            amount: formattedNearestLowerAmount,
          })
        : t('calculator.withdrawOptions', {
            lowerAmount: formattedNearestLowerAmount,
            higherAmount: formattedNearestHigherAmount,
          });

    return t('calculator.notEnoughBills', {
      hint,
    });
  };
