export const ATM_LISTING_GET_DIRECTIONS_CLICK_EVENT = 'listing-click-button-og';
export const ATM_LISTING_PHONE_NUMBER_CLICK_EVENT = 'listing-clicked-to-call-og';

export const ATM_MAP_STORES_LIST_ITEM_CLICK_EVENT = 'map-listing-click-og';
export const ATM_MAP_MARKER_CLICK_EVENT = 'map-marker-click-og';
export const ATM_MAP_DIRECTIONS_CLICK_EVENT = 'map-listing-directions-click-og';
export const ATM_MAP_MARKER_DIRECTIONS_CLICK_EVENT = 'map-marker-directions-click-og';
export const ATM_MAP_VIEW_STORE_DETAILS_CLICK_EVENT = 'map-listing-view-store-details-link-click';

export const ATM_DETAILS_VIEW_NEAR_ATM_CLICK_EVENT = 'view-near-atm-page';
export const ATM_DETAILS_NEAR_ATM_DIRECTIONS_CLICK_EVENT = 'get-directions-nearby-atms-cards';
export const ATM_DETAILS_HOW_IT_WORKS_ATM_CLICK_EVENT = 'how-it-works-top-button-batm-page';
export const ATM_DETAILS_GET_DIRECTIONS_CLICK_EVENT = 'get-directions-button-top-batm-page';

export const SURVEY_POSITIVE_REVIEW_DF_GTM_EVENT_NAME = 'direct-feedback-positive-click';
export const SURVEY_NEGATIVE_REVIEW_DF_GTM_EVENT_NAME = 'direct-feedback-negative-click';

export const ONLINE_SELL_CONFIRMED_EVENT = 'online-sell-confirmed';

// NEW Events - 19.02.2025

export const HOME_PAGE_ADDRESS_ENTERED_EVENT = 'home-page-address-entered';

export const PRE_SELL_CONTINUE_BUTTON_CLICK_EVENT = 'pre-sell-continue-button-click';
export const PRE_SELL_LOCATION_BUTTON_CLICK_EVENT = 'pre-sell-location-button-click';
export const PRE_SELL_ENTER_PHONE_NUMBER_EVENT = 'pre-sell-phone-number-entered';
export const PRE_SELL_PHONE_NUMBER_VERIFIED_EVENT = 'pre-sell-phone-number-verified';
export const PRE_SELL_AMOUNT_ENTERED_EVENT = 'pre-sell-amount-entered';
export const PRE_SELL_QR_CODE_GENERATED_EVENT = 'pre-sell-qr-code-generated';

export const HOST_ATM_SEND_REQUEST_BUTTON_CLICK_EVENT = 'host-an-atm-send-request-button-click';
