import { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';

export const removeNonDigitsFromPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/\D/g, '') || '';

export const removeCountryCodeFromPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/^\+\d+/, '');

export const cleanPhoneNumber = (phoneNumber: string): string =>
  removeNonDigitsFromPhoneNumber(removeCountryCodeFromPhoneNumber(phoneNumber));

export const getPhoneNumberMask = (lang: Lang): string => {
  switch (lang) {
    case LOCALES.EN_CA:
      return '+1 (999) 999-9999';
    case LOCALES.EN_AU:
      return '+61 9 9999 9999';
    case LOCALES.EN_HK:
    case LOCALES.ZH_HK:
      return '+852 9999 9999';
    case LOCALES.EN_NZ:
      return '+64 99 9999999';
    default:
      return '+1 (999) 999-9999';
  }
};
