import { Libraries } from '@react-google-maps/api';

import { MapFiltersType } from './types';

export const PROVINCE_KEY_TO_SLUG_MAPPING = {
  BC: 'british-columbia',
  ON: 'ontario',
  NL: 'newfoundland-and-labrador',
  NS: 'nova-scotia',
  PE: 'prince-edward-island',
  NB: 'new-brunswick',
  QC: 'quebec',
  MB: 'manitoba',
  SK: 'saskatchewan',
  AB: 'alberta',
  NT: 'northwest-territories',
  NU: 'nunavut',
  YT: 'yukon-territory',
};

export type ProvinceKey =
  | 'BC'
  | 'ON'
  | 'NL'
  | 'NS'
  | 'PE'
  | 'NB'
  | 'QC'
  | 'MB'
  | 'SK'
  | 'AB'
  | 'NT'
  | 'NU'
  | 'YT';

export const AU_STATE_TO_SLUG_MAPPING = {
  ACT: 'australian-capital-territory',
  NSW: 'new-south-wales',
  NT: 'northern-territory',
  QLD: 'queensland',
  SA: 'south-australia',
  TAS: 'tasmania',
  VIC: 'victoria',
  WA: 'western-australia',
};

export const STATE_KEY_TO_SLUG_MAPPING = {
  AL: 'alabama',
  AK: 'alaska',
  AZ: 'arizona',
  AR: 'arkansas',
  CA: 'california',
  CO: 'colorado',
  CT: 'connecticut',
  DE: 'delaware',
  DC: 'district-of-columbia',
  FL: 'florida',
  GA: 'georgia',
  HI: 'hawaii',
  ID: 'idaho',
  IL: 'illinois',
  IN: 'indiana',
  IA: 'iowa',
  KS: 'kansas',
  KY: 'kentucky',
  LA: 'louisiana',
  ME: 'maine',
  MD: 'maryland',
  MA: 'massachusetts',
  MI: 'michigan',
  MN: 'minnesota',
  MS: 'mississippi',
  MO: 'missouri',
  MT: 'montana',
  NE: 'nebraska',
  NV: 'nevada',
  NH: 'new-hampshire',
  NJ: 'new-jersey',
  NM: 'new-mexico',
  NY: 'new-york',
  NC: 'north-carolina',
  ND: 'north-dakota',
  OH: 'ohio',
  OK: 'oklahoma',
  OR: 'oregon',
  PA: 'pennsylvania',
  RI: 'rhode-island',
  SC: 'south-carolina',
  SD: 'south-dakota',
  TN: 'tennessee',
  TX: 'texas',
  UT: 'utah',
  VT: 'vermont',
  VA: 'virginia',
  WA: 'washington',
  WV: 'west-virginia',
  WI: 'wisconsin',
  WY: 'wyoming',
};

export const tagStyles = {
  orange: 'bg-primary-100 text-primary-600',
  green: 'bg-gray-100 text-green-600',
  red: 'bg-error-100 text-error-300',
};

export const tilesStyles = {
  orange: 'bg-primary-100 text-primary-600',
  green: 'bg-green-50 text-green-600',
  red: 'bg-error-100 text-error-300',
};

export const usedColors = [
  'bg-primary-100',
  'text-primary-600',
  'bg-green-50',
  'text-green-600',
  'bg-error-100',
  'text-error-300',
  'bg-gray-100',
];

export const defaultMapFilters: MapFiltersType = {
  serviceType: 'buy',
  openNow: false,
};

export const mapLibraries: Libraries = ['places'];

export const LOCATION_SEARCH_INPUT_NAME = 'location-search-input';
export const FIND_ATM_WRAPPER_CLASSNAME = 'find-atm-wrapper';
