import type { Url } from 'next/dist/shared/lib/router/router';
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';
import { formatUrl } from 'next/dist/shared/lib/router/utils/format-url';

import { addLocale } from '@/utils/locales';

export const resolveHref = (
  href: Url,
  localeProp: string | undefined | false,
  currentLocale: string,
) => {
  const resolvedHref = typeof href === 'string' ? href : formatUrl(href);

  if (isAbsoluteUrl(resolvedHref) || localeProp === false) {
    return href;
  }

  const resolvedLocale = localeProp || currentLocale;

  if (typeof href === 'string') {
    return addLocale(href, resolvedLocale);
  }

  return {
    ...href,
    pathname: addLocale(href.pathname || '/', resolvedLocale),
  };
};
