import { useLoadScript } from '@react-google-maps/api';

import { mapLibraries as libraries } from '../utils/consts';

export const useLoadGoogleMapsScript = (
  options: Partial<Parameters<typeof useLoadScript>[0]> = {},
) =>
  useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY as string,
    libraries,
    ...options,
  });
