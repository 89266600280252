import { GroupField } from '@prismicio/client';

import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';
import { Location } from '@/utils/types';

interface AvailableCoins {
  buy: Simplify<CoinsDocumentDataCoinsItem>[];
  sell: Simplify<CoinsDocumentDataCoinsItem>[];
}

export const getAvailableCoins = (
  location: Location,
  coins: GroupField<Simplify<CoinsDocumentDataCoinsItem>>,
): AvailableCoins =>
  coins.reduce<AvailableCoins>(
    (acc, value) => {
      if (!value.enabled) {
        return acc;
      }

      const coinTickerLowerCase = value.ticker?.toLocaleLowerCase() || '';

      if (location.cryptocurrencyFeatures[coinTickerLowerCase]?.buyAvailable) {
        acc.buy.push(value);
      }

      if (location.cryptocurrencyFeatures[coinTickerLowerCase]?.sellAvailable) {
        acc.sell.push(value);
      }

      return acc;
    },
    {
      buy: [],
      sell: [],
    },
  );
